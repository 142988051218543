<template>
  <div class="content-wrapper">
    <!-- Main content -->
    <section class="content">
      <breadcrumb></breadcrumb>
      <section style="margin-bottom: 1%">
        <a-button type="primary" style="width: 200px" @click="goToAddVerificationList">
          <a-icon type="plus" />
          {{ $t('添加库存盘点单') }}
        </a-button>
        <a-button type="primary" @click="visible = true" style="width: 200px">
          {{ $t('库存初始化') }}
        </a-button>
      </section>

      <a-row :gutter="16">
        <a-col :span="7">
          <a-input
            allow-clear
            v-model.trim="formData.verificationSn"
            :placeholder="$t(`请输入盘点单号`)"
          />
        </a-col>
        <a-col :span="7">
          <a-input
            allow-clear
            v-model.trim="formData.verificationName"
            :placeholder="$t(`请输入盘点名称`)"
          />
        </a-col>
        <a-col :span="7">
          <!-- 仓库名称选项 -->
          <a-select
            allowClear
            :placeholder="$t(`请选择`) + $t(`仓库`)"
            style="width: 100%"
            @change="chooseWarehouse"
          >
            <a-select-option
              v-for="item in warehouseList"
              :key="item.code"
              :value="item.warehouseCode"
            >
              {{ item.warehouseName }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row class="mt10" :gutter="16">
        <a-col :span="7">
          <a-range-picker
            style="width: 100%"
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD"
            :placeholder="[$t(`开始时间`), $t(`结束时间`)]"
            @change="onChangeDate"
          >
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-col>
        <a-col :span="4">
          <a-button icon="search" @click="getVerificationList(1)" class="btnBgc">
            {{ $t(`查询`) }}
          </a-button>
        </a-col>
      </a-row>

      <div class="table-content">
        <div class="table-header">
          <div class="row status-list-wrap">
            <input type="hidden" v-model="formData.status" />
            <a-tabs default-active-key="" @change="setSearchStatus">
              <a-tab-pane key="">
                <template slot="tab">
                  <span>{{ $t('全部') }}</span>
                </template>
              </a-tab-pane>
              <a-tab-pane key="WAIT_CONFIRM">
                <template slot="tab">
                  <span>{{ $t('待确认') }}</span>
                </template>
              </a-tab-pane>
              <a-tab-pane key="CONFIRMED">
                <template slot="tab">
                  <span>{{ $t('已确认') }}</span>
                </template>
              </a-tab-pane>
              <a-tab-pane key="CANCEL">
                <template slot="tab">
                  <span>{{ $t('已作废') }}</span>
                </template>
              </a-tab-pane>
            </a-tabs>
          </div>
          <ul class="action-list" v-show="tableData.length">
            <li>
              <!-- 批量作废 -->
              <a-button class="btnBgD" @click="batchCancel">
                {{ $t(`批量作废`) }}
              </a-button>
            </li>
          </ul>
        </div>
        <div class="table-body">
          <a-table
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :scroll="{ x: 1100, y: tableHeight }"
            :columns="tableColumns"
            :data-source="tableData"
            rowKey="inventoryVerificationId"
            :pagination="false"
            bordered
          >
            <!-- 盘点单号 -->
            <template slot="VerificationNumber" slot-scope="text, record">
              <a @click="onCliCkDetail(record.inventoryVerificationId)">
                {{ record.verificationSn }}
              </a>
            </template>
            <!-- 盘点时间 -->
            <template slot="VerTime" slot-scope="text, record">
              {{ record.verifiedTime.slice(0, 10) }}
            </template>
            <!-- 创建时间 -->
            <template slot="CreateAt" slot-scope="text, record">
              {{ record.createdAt.slice(0, 10) }}
            </template>
          </a-table>
        </div>
        <div class="table-footer mt8">
          <pagination
            :pageNo="pageNo"
            :length="pageSize"
            :total-count="total"
            @paginate="paginate"
          ></pagination>
        </div>
      </div>
    </section>
    <a-modal
      :title="$t(`库存初始化`)"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="visible = false"
    >
      <div :style="{ borderBottom: '1px solid #E9E9E9', paddingBottom: '8px' }">
        <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
          {{ $t('全选') }}
        </a-checkbox>
      </div>
      <div style="padding: 10px 0">
        <a-checkbox-group
          v-model="checkedList"
          @change="onChange"
          style="display: flex; flex-wrap: wrap"
        >
          <a-checkbox
            v-for="item in warehouseList"
            :value="item"
            :key="item.warehouseCode"
            style="width: 45%"
          >
            {{ item.warehouseName }}
          </a-checkbox>
        </a-checkbox-group>
      </div>
    </a-modal>
  </div>
</template>
<script>
import http from '@common/http'
import api from '@common/api'
import { mapMutations } from 'vuex'
import pagination from '@component/pagination'
import { Modal } from 'ant-design-vue'
export default {
  name: 'inventoryVerification',
  mounted() {
    this.getVerificationList()
    this.getWarehouseList()
  },
  activated() {
    this.getVerificationList()
  },
  components: {
    pagination,
  },
  data() {
    return {
      warehouseList: [], //仓库列表
      status: '',
      formData: {
        verificationSn: '',
        verificationName: '',
        warehouseCode: '',
        status: '',
        verifiedTimeStart: '',
        verifiedTimeEnd: '',
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      selectedRowKeys: [], //选中的盘点单
      modalInfo: {
        modalTitle: '',
        modalDesc: '',
        modalUrl: '',
        xlsRequestUrl: '',
        xlsDownloadUrl:
          'http://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E9%87%87%E8%B4%AD%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx', //模板下载链接
      },
      visible: false,
      confirmLoading: false,
      checkedList: [],
      indeterminate: false,
      checkAll: false,
    }
  },
  computed: {
    tableColumns() {
      return [
        // 盘点单号
        {
          title: this.$t('盘点单号'),
          key: 'VerificationNumber',
          align: 'center',
          fixed: 'left',
          width: '200px',
          scopedSlots: { customRender: 'VerificationNumber' },
        },
        // 盘点名称
        {
          title: this.$t('盘点名称'),
          dataIndex: 'verificationName',
          align: 'center',
          key: 'VerificationName',
        },
        // 盘点单状态
        {
          title: this.$t('盘点单状态'),
          key: 'VerificationStatus',
          align: 'center',
          dataIndex: 'status',
          customRender: (text) => this.getStatus(text),
        },
        // 所属仓库
        {
          title: this.$t('所属仓库'),
          key: 'BelongingToWarehouse',
          align: 'center',
          dataIndex: 'warehouseName',
        },
        //  盘点时间
        {
          title: this.$t('盘点时间'),
          key: 'VerificationTime',
          align: 'center',
          dataIndex: 'verifiedTime',
          scopedSlots: { customRender: 'VerTime' },
        },
        //  创建时间
        {
          title: this.$t('创建时间'),
          key: 'CreateAt',
          align: 'center',
          dataIndex: 'createdAt',
          scopedSlots: { customRender: 'CreateAt' },
        },
        //备注
        {
          title: this.$t('备注'),
          key: 'note',
          align: 'center',
          dataIndex: 'note',
        },
      ]
    },
    tableHeight() {
      return (window.innerHeight - 490) * this.$store.state.rate
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    // 时间范围查询
    onChangeDate(value, dateString) {
      this.formData.verifiedTimeStart = `${dateString[0]} 00:00:00`
      this.formData.verifiedTimeEnd = `${dateString[1]} 23:59:59`
      console.log(this.formData)
    },
    getStatus(text) {
      return this.$t(`${text}`)
    },
    // 获取所属仓库下拉列表数据
    getWarehouseList() {
      http({
        instance: this,
        url: api.warehouseList,
        type: 'post',
        data: {},
        success: (res) => {
          this.warehouseList = [...res.result]
        },
      })
    },
    // 选择仓库
    chooseWarehouse(value) {
      if (value) {
        this.formData.warehouseCode = value
      } else {
        this.formData.warehouseCode = ''
      }
    },
    // 多选框选择
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    // 批量作废
    batchCancel() {
      this.batchHandle({
        url: api.inventoryVerificationBatchCancel,
      })
    },
    // 批量操作是判断是否选中
    confirmSelect() {
      let is_empty = false
      if (this.selectedRowKeys.length === 0) {
        is_empty = true
        this.$warning({
          title: this.$t(`警告`),
          content: this.$t('至少选中一项'),
        })
      }
      return is_empty
    },
    // 批量操作
    batchHandle({ url }) {
      if (this.confirmSelect()) return
      Modal.confirm({
        title: 'Confirm',
        content: this.$t('是否作废选中订单'),
        okText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        onOk: () => {
          let inventoryVerificationIds = [...this.selectedRowKeys]
          this.commonHttp({
            url,
            data: { inventoryVerificationIds },
          }).then(() => {
            this.getVerificationList()
          })
        },
      })
    },
    commonHttp({ url, data }) {
      return new Promise((resolve) => {
        http({
          instance: this,
          type: 'post',
          hasLoading: true,
          url,
          data,
          success: (res) => {
            this.$success({
              title: this.$t(`Success`),
              content: this.$t('作废已完成'),
            })
            resolve(res)
          },
        })
      })
    },
    // 点击盘点单号去详情页
    onCliCkDetail(id) {
      console.log(id)
      this.$router.push({
        name: 'addVerificationList',
        query: { id },
      })
    },
    // 去添加盘点单页面
    goToAddVerificationList() {
      this.$router.push('addVerificateResult')
    },
    // 获取盘点单列表
    getVerificationList(first_page = false) {
      console.log(this.formData)
      first_page && (this.pageNo = 1)
      this.$nextTick(() => {
        let data = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          verificationName: this.formData.verificationName,
          verificationSn: this.formData.verificationSn,
          verifiedTimeStart: this.formData.verifiedTimeStart,
          verifiedTimeEnd: this.formData.verifiedTimeEnd,
          warehouseCode: this.formData.warehouseCode,
          status: this.formData.status,
        }
        if (this.formData.status !== -1) {
          data.status = this.formData.status
        }

        http({
          instance: this,
          type: 'post',
          url: api.inventoryVerificationList,
          hasLoading: true,
          data,
          success: (res) => {
            let verificationList = [...(res.result.inventoryVerificationList || '')]
            this.tableData = verificationList
            this.total = res.result.total
          },
        })
      })
    },
    // 状态筛选
    setSearchStatus(status) {
      this.pageNo = 1 //切换状态时 将页码变为1
      this.$set(this.formData, 'status', status)
      this.getVerificationList()
      this.selectedRowKeys = [] //清空上个状态选择的
    },
    // 分页功能
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.getVerificationList()
    },
    // 库存初始化确认
    async handleOk() {
      if (!this.checkedList.length) {
        this.$warning({
          title: this.$t(`警告`),
          content: this.$t(`请选择`) + this.$t(`仓库`),
        })
        return
      }
      let shopCode = JSON.parse(localStorage.getItem('currentShop')).uniqueCode
      let buId = this.$store.getters.partyId
      let warehouseCodeList = []
      this.checkedList.forEach((v) => {
        warehouseCodeList.push(v.warehouseCode)
      })
      this.confirmLoading = true
      await http({
        instance: this,
        type: 'post',
        url: api.createInventoryVerificationByApi,
        data: {
          buId,
          shopCode,
          warehouseCodeList,
        },
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`Success`),
              content: this.$t(`初始化成功`),
            })
            this.visible = false
            this.getVerificationList()
          }
        },
      })
      this.confirmLoading = false
    },
    onChange(checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.warehouseList.length
      this.checkAll = checkedList.length === this.warehouseList.length
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.warehouseList : [],
        indeterminate: false,
        checkAll: e.target.checked,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.btnBgc {
  background-color: #0088ff;
  color: #ffffff;
  margin-right: 30px !important;
}
.btnBgD {
  background-color: #ff4d4f;
  color: #ffffff;
}
.batchBgc {
  color: #0088ff;
  border-color: #0088ff;
}
.action-list {
  display: flex;
}
.flex-btns {
  max-width: 120px;
  &.flex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .t-btn {
    cursor: pointer;
    margin-right: 10px;
    color: $primary-color;
    white-space: nowrap;
  }
}
.upload-content {
  padding: 20px;
}
.mt10 {
  margin-top: 10px;
  display: block;
}
</style>
